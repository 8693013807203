<template>
  <a-modal
    title="Confirmação de segurança"
    :visible="isVisible"
    :confirmLoading="isLoading"
    :ok-button-props="{ disabled: !recaptchaVerified, loading: isLoading }"
    :destroyOnClose="true"
    cancelText="Cancelar"
    :rules="rules"
    okText="Confirmar"
    @ok="handleOk"
    @cancel="handleClosed"
    width="375px"
  >
    <a-form v-on:submit.prevent="checkIfRecaptchaVerified">
      <div class="input-group">
        <vue-recaptcha
          ref="recaptcha"
          sitekey="6LfcoQ0UAAAAAFy8Y30sL0o9Ix3aOz8QQxFNid2Q"
          @verify="markRecaptchaAsVerified"
        >
        </vue-recaptcha>
      </div>
      <div v-cloak class="">
        <div v-show="serverError">
          {{ serverError }}
        </div>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { mapMutations, mapState, mapActions } from "vuex";
// import { RightOutlined } from "@ant-design/icons-vue";
import { VueRecaptcha } from "vue-recaptcha";
import { message } from "ant-design-vue";

export default defineComponent({
  setup() {
    const formState = reactive({
      inputCaptcha: "",
    });
    return { formState };
  },
  components: { VueRecaptcha },
  props: {
    applicationId: { type: String },
    isVisible: { type: Boolean, default: false },
    handleClose: { type: Function, default: () => {} },
    adminMode: { type: Boolean, default: false },
  },
  computed: {
    ...mapState("application", ["isLoading"]),
  },
  data() {
    return {
      image: "",
      recaptchaVerified: false,
      pleaseTickRecaptchaMessage: "",
      rules: {
        inputCaptcha: [
          {
            required: true,
            message: "Campo obrigatorio",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    ...mapActions("application", ["submitProcess"]),
    async handleOk() {
      try {
        await this.submitProcess({ id: this.applicationId, adminMode: this.adminMode });

        message.success("Sua aplicação foi encaminhada para análise!");
        this.handleClosed(false);
      } catch (e) {
        console.log(e);
      }
    },
    markRecaptchaAsVerified() {
      this.pleaseTickRecaptchaMessage = "";
      this.recaptchaVerified = true;
    },
    checkIfRecaptchaVerified() {
      if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage = "Please tick recaptcha.";
        return true;
      }
    },
    async handleClosed() {
      this.$emit("handleClose");
    },
  },
  watch: {
    async applicationId() {
      if (this.applicationId != "") {
        this.image = null;
      }
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
