<template>
  <div :class="$style.content">
    <Captcha
      :applicationId="applicationId"
      :isVisible="isSecurityModalVisible"
      :adminMode="adminMode"
      @handleClose="handleClose"
    />
    <div :class="$style.myProcessTitle">{{ allProcessTitle }}</div>
    <div :class="$style.toolBar" v-show="hasSelected">
      <a-popconfirm
        title="Você tem certeza?"
        @confirm="deleteProcess()"
        :disabled="!hasOne"
      >
        <template #icon><question-circle-outlined style="color: red" /></template>
        <a-tooltip :title="hasOne ? '' : JUST_ONE_PROCESS" color="#f50">
          <a-button :disabled="!hasOne" type="danger" size="small">
            <template #icon>
              <DeleteOutlined />
            </template>
            Remover
          </a-button>
        </a-tooltip>
      </a-popconfirm>
    </div>
    <div :class="$style.tableTravel">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :class="$style.tableColumm"
        size="middle"
        :loading="isLoading"
        :row-key="(record) => record.id"
        tableLayout="auto"
        :pagination="tablePagination"
        :columns="columns"
        :data-source="applications"
        @change="onChange"
      >
        <template #customTitle>
          <a-space>
            ID da Aplicação
            <a-tooltip placement="top" :title="APPLICATION_ID">
              <a-button shape="circle" size="small">
                <template #icon><QuestionOutlined /></template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
        <template #applicationIdRender="{ record }">
          <span v-if="record.status == STATUS_PROCESSED || adminMode">
            {{ record.content.applicationIdUS }}
          </span>
          <span v-else> - </span>
        </template>
        <template #nameRender="{ record }">
          <span v-if="record.content">
            <a
              :href="
                adminMode
                  ? '/applications/' + record.id + '?adminMode=true'
                  : '/applications/' + record.id
              "
            >
              {{ record.name }}
            </a>
          </span>
          <span v-else>{{ record.name }}</span>
        </template>
        <template #actionsRender="{ record }">
          <a-tooltip
            :title="
              !isSystemEnable
                ? APPLICATION_WITHOUT_SUBSCRIPTION
                : record.isReady
                ? ''
                : APPLICATION_NOT_ABLE_TO_SEND
            "
            color="#f50"
          >
            <a-button
              type="primary"
              size="small"
              v-if="record.status == STATUS_SAVED || record.status == STATUS_ERROR"
              :loading="record.id == applicationId && isLoadingSubmit"
              :disabled="
                !isSystemEnable ||
                (applicationId != '' && record.id != applicationId && isLoadingSubmit) ||
                !record.isReady
              "
              @click="submitProcess(record.id)"
            >
              <template #icon>
                <SendOutlined />
              </template>
              {{ isDesktop ? "Enviar para análise" : "Submeter" }}
            </a-button>
          </a-tooltip>
          <div v-if="record.status == STATUS_PROCESSED && adminMode">
            <a-popconfirm
              title="Você tem certeza?, pode ter efeitos negativos para os usuarios"
              @confirm="clone(record)"
            >
              <a-button type="danger" size="small">
                <template #icon>
                  <CopyOutlined />
                </template>
                Clonar Aplicação
              </a-button>
            </a-popconfirm>
            <a-button size="small" @click="viewResume(record)">
              <template #icon>
                <DownloadOutlined />
              </template>
              Baixar Resumo
            </a-button>
          </div>
          <a-button
            type="primary"
            size="small"
            v-if="record.status == STATUS_PROCESSED"
            @click="view(record)"
          >
            <template #icon>
              <DownloadOutlined />
            </template>
            Baixar Confirmação
          </a-button>
        </template>
        <template #statusRender="{ record }">
          <a-tag color="success" v-if="record.status == STATUS_PROCESSED">
            <template #icon>
              <check-circle-outlined />
            </template>
            Analisado
          </a-tag>
          <a-tag color="success" v-if="record.status == STATUS_IN_REVIEW">
            <template #icon>
              <sync-outlined :spin="true" />
            </template>
            Em Revisão
          </a-tag>
          <a-tag color="processing" v-if="record.status == STATUS_PROCESSING">
            <template #icon>
              <sync-outlined :spin="true" />
            </template>
            Em análise
          </a-tag>
          <a-tag color="default" v-if="record.status == STATUS_SAVED">
            <template #icon>
              <CheckOutlined />
            </template>
            Salvo
          </a-tag>

          <a-tag color="error" v-if="record.status == STATUS_ERROR">
            <template #icon>
              <close-circle-outlined />
            </template>
            Entre em contato conosco
          </a-tag>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { computed, reactive, toRefs, defineComponent } from "vue";
import { message } from "ant-design-vue";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CheckOutlined,
  DownloadOutlined,
  SendOutlined,
  DeleteOutlined,
  QuestionOutlined,
  CopyOutlined,
} from "@ant-design/icons-vue";
import {
  STATUS_SAVED,
  STATUS_PROCESSING,
  STATUS_PROCESSED,
  STATUS_IN_REVIEW,
  STATUS_ERROR,
} from "@/utils/Constants";
import {
  APPLICATION_ID,
  JUST_ONE_PROCESS,
  APPLICATION_NOT_ABLE_TO_SEND,
  APPLICATION_WITHOUT_SUBSCRIPTION,
} from "@/utils/tooltipConstants";
import { isDesktop } from "@/utils/isAgent";
import Captcha from "@/components/Captcha/Captcha";

const columns = isDesktop
  ? [
      {
        dataIndex: "applicationIdUS",
        sortDirections: ["descend", "ascend"],
        slots: {
          title: "customTitle",
          customRender: "applicationIdRender",
        },
      },
      {
        title: "Nome completo",
        dataIndex: "name",
        sorter: true,
        sortDirections: ["descend"],
        slots: {
          customRender: "nameRender",
        },
      },
      {
        title: "Data de Nascimento",
        dataIndex: "content.personal1BirthDay",
        filterMultiple: false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: true,
        filters: [
          {
            text: "Salvo",
            value: "0",
          },
          {
            text: "Analisando",
            value: "1",
          },
          {
            text: "Processado",
            value: "2",
          },
        ],
        filterMultiple: false,
        // onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        sortDirections: ["descend"],
        slots: {
          customRender: "statusRender",
        },
      },
      {
        title: "Ações",
        dataIndex: "id",
        filterMultiple: false,
        slots: {
          customRender: "actionsRender",
        },
      },
    ]
  : [
      {
        title: "Nome",
        dataIndex: "name",
        sorter: true,
        sortDirections: ["descend"],
        slots: {
          customRender: "nameRender",
        },
      },
      {
        title: "Ações",
        dataIndex: "id",
        filterMultiple: false,
        slots: {
          customRender: "actionsRender",
        },
      },
    ];

export default defineComponent({
  setup() {
    const state = reactive({
      selectedRowKeys: [],
      // Check here to configure the default column
      loading: false,
      image: null,
    });
    const hasOne = computed(() => state.selectedRowKeys.length == 1);

    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };

    return {
      columns,
      STATUS_ERROR,
      STATUS_PROCESSED,
      STATUS_IN_REVIEW,
      STATUS_PROCESSING,
      STATUS_SAVED,
      APPLICATION_NOT_ABLE_TO_SEND,
      APPLICATION_WITHOUT_SUBSCRIPTION,
      hasOne,
      ...toRefs(state),
      onSelectChange,
      APPLICATION_ID,
      JUST_ONE_PROCESS,
    };
  },
  components: {
    CheckCircleOutlined,
    SyncOutlined,
    CheckOutlined,
    DownloadOutlined,
    SendOutlined,
    DeleteOutlined,
    QuestionOutlined,
    Captcha,
    CopyOutlined,
  },
  props: {
    allProcessTitle: {
      type: String,
      default: "Minhas aplicações",
    },
    adminMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      applicationId: "",
      isSecurityModalVisible: false,
      isDesktop,
    };
  },
  computed: {
    ...mapState("application", [
      "isLoading",
      "applications",
      "pagination",
      "isLoadingSubmit",
    ]),
    ...mapGetters("user", ["isAdmin"]),
    ...mapGetters("system", ["isSystemEnable"]),
    tablePagination() {
      return {
        total: this.pagination.totalItems,
        current: this.pagination.currentPage,
        pageSize: this.pagination.itemsPerPage,
      };
    },
    hasSelected() {
      return this.selectedRowKeys.length && this.applications.length;
    },
  },
  methods: {
    ...mapActions("application", [
      "getApplications",
      "removeProcess",
      "getConfirmation",
      "getResume",
      "cloneApplication",
      "getManagerApplications",
    ]),
    ...mapActions("user", ["refreshLicenses"]),

    async downloadDS(id) {
      alert(id);
    },
    showModal() {
      this.isSecurityModalVisible = true;
    },

    async submitProcess(id) {
      this.applicationId = id;
      this.showModal();
    },
    generatePDF(fileName, base64Data) {
      const linkSource = `data:application/pdf;base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName}.pdf`;
      downloadLink.click();
    },
    async view(record) {
      const { id, name } = record;

      const fileName = name.replaceAll(" ", "-").toLowerCase() + "-confirmacao";

      const base64Data = await this.getConfirmation(id);
      this.generatePDF(fileName, base64Data);
    },

    async viewResume(record) {
      const { id, name } = record;

      const fileName = name.replaceAll(" ", "-").toLowerCase() + "-resume";

      const base64Data = await this.getResume(id);
      this.generatePDF(fileName, base64Data);
    },

    async clone(record) {
      const { id, name } = record;
      await this.cloneApplication(id);
      message.success(`Aplicação de ${name} foi clonada com successo`);
    },
    async handleClose() {
      this.applicationId = "";
      this.isSecurityModalVisible = false;
    },
    async deleteProcess() {
      await this.removeProcess(this.selectedRowKeys[0]);
      this.selectedRowKeys = [];
    },
    async onChange(pagination, filters, sorter) {
      const hasOrder = !sorter?.order || sorter?.order == "ascend" ? true : false;
      const filtersKey = Object.keys(filters)[0];
      const data = {
        page: pagination.current,
        where:
          filtersKey && filters
            ? {
                [filtersKey]: filters[filtersKey][0],
              }
            : {},
        order: {
          column: sorter.field,
          order: hasOrder ? "ASC" : "DESC",
        },
      };
      if (this.adminMode) {
        await this.getManagerApplications(data);
      } else {
        await this.getApplications(data);
      }
    },
  },
  async mounted() {
    try {
      if (this.adminMode) {
        await this.getManagerApplications();
      } else {
        await this.getApplications();
      }
      await this.refreshLicenses();
    } catch (e) {
      console.log(e);
    }
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
