<template>
  <div style="margin-bottom: 10px">
    <a-row>
      <a-col v-if="!hideLicenses" :span="24">
        <div :class="$style.amount">
          <a-alert
            v-if="isSystemEnable"
            :message="`Você tem direito de enviar ${amountSubscriptionAvailable} aplicações para a análise de nossos consultores`"
            type="success"
            showIcon
          >
            <template #icon><smile-outlined /></template>
          </a-alert>
          <a-alert
            v-else
            message="Suas aplicações acabaram, viste a página de compra para adquirir mais"
            type="error"
            showIcon
          >
            <template #icon><FrownOutlined /></template>
          </a-alert></div
      ></a-col>
      <a-col :span="isDesktop ? 24 : 24">
        <a-input-search
          size="large"
          v-model:value="value"
          :loading="isLoading"
          placeholder="Buscar por..."
          @search="onSearch"
      /></a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { isDesktop } from "@/utils/isAgent";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  setup() {
    const value = ref("");
    return { value, isDesktop };
  },
  components: {
    SmileOutlined,
    FrownOutlined,
  },
  props: {
    hideLicenses: {
      type: Boolean,
      default: false,
    },
    adminMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("application", ["getApplications", "getManagerApplications"]),
    async onSearch(searchValue) {
      const data = {
        where: {
          name: searchValue,
        },
      };
      if (this.adminMode) {
        await this.getManagerApplications(data);
      } else {
        await this.getApplications(data);
      }
    },
  },
  computed: {
    ...mapState("application", ["isLoading"]),
    ...mapState("system", ["amountSubscriptionAvailable"]),
    ...mapGetters("system", ["isSystemEnable"]),
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
